{
    "Disconnected": "Disconnected",
    "Loading...": "Loading...",
    "Lobby was closed": "Lobby was closed",
    "Reconnected": "Reconnected",
    "${0} left": "${0} left",
    "${0}s": "${0}s",
    "Back to lobby": "Back to lobby",
    "pictionary": {
        "Enter message...": "Enter message...",
        "Waiting for ${0}": "Waiting for ${0}",
        "Next Round": "Next Round",
        "Scores": "Scores",
        "German": "German",
        "Wordlist": "Wordlist"
    },
    "stadt-land-fluss": {
        "Next round": "Next round",
        "Stop ${0}/${1}": "Stop ${0}/${1}",
        "${0} starting with ${1}": "${0} starting with ${1}",
        "Words starting with ${0}": "Words starting with ${0}",
        "${0} (+${1}) Points": "${0} (+${1}) Points",
        "<empty>": "<empty>",
        "Approve": "Approve",
        "Deny": "Deny",
        "Upvote": "Upvote",
        "Upvoted": "Upvoted"
    },
    "${0} Points": "${0} Points",
    "stille-post": {
        "by ${0}": "by ${0}",
        "Finish": "Finish",
        "Previous Page": "Previous Page",
        "Waiting for next page": "Waiting for next page",
        "Your Page": "Your Page",
        "Close Book": "Close Book",
        "Next Page": "Next Page",
        "Alternate": "Alternate",
        "Draw": "Draw",
        "First Page": "First Page",
        "Following Pages": "Following Pages",
        "Random": "Random",
        "Write": "Write"
    },
    "taboo": {
        "Start": "Start",
        "You're next": "You're next",
        "Guessed": "Guessed",
        "Skip": "Skip",
        "You're explaining": "You're explaining",
        "You're guessing": "You're guessing",
        "You're observing": "You're observing",
        "Skipped": "Skipped",
        "Timed Out": "Timed Out"
    },
    "Playing as ${0}": "Playing as ${0}",
    "Game History": "Game History",
    "${0} Point(s)": "${0} Point(s)",
    "Copied to Clipboard!": "Copied to Clipboard!",
    "Copy": "Copy",
    "Game": "Game",
    "Link": "Link",
    "Lobby Settings": "Lobby Settings",
    "Room Code": "Room Code",
    "Start Game": "Start Game",
    "Seconds": "Seconds",
    "Timer": "Timer",
    "Add": "Add",
    "Add Column": "Add Column",
    "Column": "Column",
    "Columns": "Columns",
    "${0} (You)": "${0} (You)",
    "Join Team": "Join Team",
    "Players": "Players",
    "Avatar": "Avatar",
    "Cancel": "Cancel",
    "Player setup": "Player setup",
    "Save": "Save",
    "Username": "Username",
    "Connect to Lobby": "Connect to Lobby",
    "Create a Lobby": "Create a Lobby",
    "Enter Room Code": "Enter Room Code",
    "Join a Lobby": "Join a Lobby",
    "or": "or"
}