{
    "Disconnected": "Verbindung getrennt",
    "Loading...": "Lädt...",
    "Lobby was closed": "Lobby wurde geschlossen",
    "Reconnected": "Verbunden",
    "${0} left": "${0} left",
    "${0}s": "${0}s",
    "Back to lobby": "Zurück zur Lobby",
    "pictionary": {
        "Enter message...": "Nachricht eingeben...",
        "Waiting for ${0}": "Warte auf ${0}",
        "Next Round": "Nächste Runde",
        "Scores": "Punkte",
        "German": "Deutsch",
        "Wordlist": "Wortliste"
    },
    "stadt-land-fluss": {
        "Next round": "Nächste Runde",
        "Stop ${0}/${1}": "Fertig ${0}/${1}",
        "${0} starting with ${1}": "${0} mit ${1}",
        "Words starting with ${0}": "Wörter mit ${0}",
        "${0} (+${1}) Points": "${0} (+${1}) Punkte",
        "<empty>": "<leer>",
        "Approve": "Approve",
        "Deny": "Ablehnen",
        "Upvote": "Kreativ",
        "Upvoted": "Kreativ"
    },
    "${0} Points": "${0} Punkte",
    "stille-post": {
        "by ${0}": "von ${0}",
        "Finish": "Fertig",
        "Previous Page": "Vorherige Seite",
        "Waiting for next page": "Warte auf nächste Seite...",
        "Your Page": "Deine Seite",
        "Close Book": "Buch schließen",
        "Next Page": "Nächste Seite",
        "Alternate": "Abwechselnd",
        "Draw": "Zeichnen",
        "First Page": "Erste Seite",
        "Following Pages": "Folgende Seiten",
        "Random": "Zufällig",
        "Write": "Schreiben"
    },
    "taboo": {
        "Start": "Start",
        "You're next": "Du bist als nächstes dran",
        "Guessed": "Erraten",
        "Skip": "Nächste",
        "You're explaining": "Du musst erklären",
        "You're guessing": "Du musst raten",
        "You're observing": "Du beobachtest",
        "Skipped": "Übersprungen",
        "Timed Out": "Zeit ist abgelaufen"
    },
    "Playing as ${0}": "Benutzername: ${0}",
    "Game History": "Spielverlauf",
    "${0} Point(s)": "${0} Punkt(e)",
    "Copied to Clipboard!": "Kopiert!",
    "Copy": "Kopieren",
    "Game": "Spiel",
    "Link": "Link",
    "Lobby Settings": "Lobby Einstellungen",
    "Room Code": "Raumcode",
    "Start Game": "Spiel starten",
    "Seconds": "Sekunden",
    "Timer": "Zeit",
    "Add": "Hinzufügen",
    "Add Column": "Spalte hinzufügen",
    "Column": "Spalte",
    "Columns": "Spalten",
    "${0} (You)": "${0} (Du)",
    "Join Team": "Team beitreten",
    "Players": "Spieler",
    "Avatar": "Avatar",
    "Cancel": "Abbrechen",
    "Player setup": "Spieler einrichten",
    "Save": "Speichern",
    "Username": "Benutzername",
    "Connect to Lobby": "Mit Lobby verbinden",
    "Create a Lobby": "Lobby erstellen",
    "Enter Room Code": "Raumcode eingeben",
    "Join a Lobby": "Lobby beitreten",
    "or": "oder"
}
